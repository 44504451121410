import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import emitter from './helpers/useEmitter';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/scss/style.scss'
import Moment from 'moment';
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import es from './locales/es.json'
import pt from './locales/pt.json'

axios.defaults.baseURL = 'https://backend.oasis-tlc.com/'
// axios.defaults.baseURL = 'http://localhost:5000/'

const i18n = createI18n({
    legacy: false,
    warnHtmlMessage: false,
    locale: 'es',
    fallbackLocale: 'en',
    messages: {
      en,
      es,
      pt
    }
})

const app = createApp(App)

app.config.globalProperties.emitter = emitter
app.use(i18n)
app.use(VueSweetalert2)
app.use(Moment)
app.use(store)
app.use(router)
window.emitter = emitter
app.config.globalProperties.$mitt = emitter
app.mount('#app')