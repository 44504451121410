import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import avalon from '@/modules/avalon'
import emitter from '@/helpers/useEmitter'
import { isValidJwt, isAdmin } from '@/helpers/auth'
import { authRequest } from '@/helpers/avalon/requests'

export default createStore({
  state: {
    user: {},
    jwt: null,
    title: '',
    selectedTab: 'summaryPaymentMethod',
    filterWalletPay: {
      estatus_contrato:"",
      sala: "",
      contrato: "",
    },
  },
  getters: {
    isAuthenticated(state){
      if (localStorage.getItem('jwt') !== null) return isValidJwt(JSON.parse(localStorage.getItem('jwt')))
      else return false
    },
    getTitle(state){
      return state.title
    },
    isAdmin(state){
      return isAdmin(JSON.parse(localStorage.getItem('jwt')))
    },
    getSelectedTab(state){
      return state.selectedTab
    },
    getFilterWalletPayment(state){
      return state.filterWalletPay
    },
  },
  mutations: {
    setUserData(state, payload){
      let theUser = Object.fromEntries(payload.userData)
      delete theUser['password']
      state.user = JSON.stringify(theUser)
    },
    setJwtToken(state, payload){
      if (payload){
        localStorage.setItem('jwt', JSON.stringify(payload.jwt))
      }else{
        localStorage.removeItem('jwt')
        localStorage.removeItem('sesionC')
      }
    },
    setTitle(state, title){
      localStorage.setItem('title', title)
      state.title = localStorage.getItem('title')
    },
    setSelectedTab(state, tab){
      state.selectedTab = tab
    },
    setFilterWalletPayment(state, filter){
      state.filterWalletPay = filter
    },
    clean_filterWalletPayment(state){
      const wallet = {
        estatus_contrato:"",
        sala: "",
        contrato: "",
      }
      state.filterWalletPay = wallet;
    },
  },
  actions: {
    login(context, userData) {
      context.commit('setUserData', { userData })
      return authRequest('/login', userData)
        .then(rsp  => context.commit('setJwtToken', {jwt: rsp.data}))
        .catch((error) => {
          context.commit('setJwtToken', {jwt: ''})
          emitter.emit('failedAuth', error)
        })
    },
    register({context}, data) {
      return authRequest('/register', data.userData, data.jwt)
        .then(() => console.log('registrado'))
    },
    change_password(context, userData) {
      return authRequest('/change/password', userData)
        .then(rsp => context.commit('setJwtToken', {jwt: rsp.data}))
    },
    setTab({commit}, tab){
      commit('setSelectedTab', tab)
    },
  },
  modules: {
    avalon
  },
  plugins: [createPersistedState()]
})
