import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import AboutView from '@/views/AboutView'


import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      { path: 'index', name: 'index', component: AboutView }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: HomeView,
    children:[
      { path: '', name:'dash', component: () => import('@/views/system/AdminView.vue') },
      { path: 'access', name: 'access', component: () => import('@/views/system/UserFunctionsView.vue') },
      { path: 'user', name: 'user', component: () => import('@/views/system/UserView.vue') }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/fauth/LoginView.vue')
  },
  {
    path: '/otlc',
    component: HomeView,
    children: [
      { path: 'table/:table/list/:page', name:'contracts', component: () => import('@/views/otlc/ContractView') },
      { path: 'reports',
        children: [
          { path : 'collection', 
            children: [
              { path: ':table/:page', name: 'collection', component: () => import('@/views/otlc/reports/CollectionView.vue') },
              { path: 'planning/:table/:page', name: 'collection-planning', component: () => import('@/views/otlc/reports/CollectionPlanningView.vue') },
              { path: 'account/status/:table/:page', name: 'collection-account-status', component: () => import('@/views/otlc/reports/CollectionAccountStatusView.vue') },
              { path: 'binnacle/:table/:page', name: 'binnacle', component: () => import('@/views/otlc/reports/BinnacleView.vue') },
              { path: 'log/:table/:page', name: 'log', component: () => import('@/views/otlc/reports/LogView.vue') },
              { path: 'summary/:page', name: 'summary', component: () => import('@/views/otlc/reports/CollectionContractView.vue') },
              { path: 'statistics-grafic', name: 'statistics-grafic', component: () => import('@/views/otlc/dashboard_contracts/ListView.vue') },
              { path: 'bank-report', name: 'bank-report', component: () => import('@/views/otlc/reports/bank/ReportBank.vue') },
              { path: 'contract-report', name: 'contract-report', component: () => import('@/views/otlc/reports/Contract/ContractView.vue')},
              { path: 'consult-contract', name: 'consult-contract', component: () => import('@/views/otlc/reports/ConsultContract/ConsultContract.vue')},
            ]
          }
        ]
      },
      { path : 'collection', 
        children: [
          { path: 'management/:table', name: 'management', component: () => import('@/views/otlc/collection/CollectionManagementView.vue') },
          { path: 'seniority/:table/:page', name: 'management-balances', component: () => import('@/views/otlc/collection/CollectionSeniorityBalancesView.vue') },
          { path: 'operation/:table/:page', name: 'management-operation', component: () => import('@/views/otlc/collection/CollectionSeniorityOperationView.vue') },
          { path: 'consultation', name: 'consultation-management', component: () => import('@/views/otlc/collection/ConsultationCollectionView.vue') },
          { path: 'closing/:table/:page', name: 'closing-dates', component: () => import('@/views/otlc/collection/CollectionClosingDateView.vue') },
          { path: 'current/:table/:page', name: 'recurrent-send', component: () => import('@/views/otlc/collection/CollectionRecurrentSendView.vue') },
          { path: 'recurrent/charges/:page', name: 'recurrent-charges', component: () => import('@/views/otlc/collection/CollectionRecurrentChargesView.vue') },
          { path: 'wire/transfer/:page', name: 'wire-transfer', component: () => import('@/views/otlc/collection/CollectionWireTransferView.vue') },
          { path: 'wallet-payment/:page', name: 'wallet-payment', component: () => import('@/views/otlc/reports/walletPayment/WalletPayment.vue') },
          { path: 'account/status/mailing', name: 'mailing-account-satus', component: () => import('@/views/otlc/collection/StatusAccountMailingView.vue')},
          { path: 'stripe/transfer', name: 'stripe-transfer', component: () => import('@/views/otlc/collection/CollectionStripeView.vue') },
          { path: 'stripe/verify', name:'stripe-verify', component: () => import('@/views/otlc/collection/VerifyPaymentsView.vue')},
        ]
      },
      { path : 'utilities', 
        children: [
          { path: 'card', name: 'utilities-card', component: () => import('@/views/otlc/utilities/CardManagementView.vue') },
        ]
      },
      { path : 'reservations',
        children: [
          { path: 'info/contract', name: 'consultation-contract', component: () => import('@/views/otlc/collection/ConsultationCollectionView.vue') },
          { path: 'reservations-otlc/:page', name: 'reservations-otlc', component: () => import('@/views/otlc/reservations/ReservationsView.vue') },
          { path: 'rates', name: 'rates-otlc', component: () => import('@/views/otlc/reservations/rates/ChargingFeesView.vue')},
          { path: 'exchange-rate', name: 'tc-otlc', component: () => import('@/views/otlc/reservations/exchange_rate/ExchangeRateView.vue')},
          { path: 'customers/:page', name: 'r-type-user', component: () => import('@/views/otlc/reservations/customers/customersTypeView.vue')},
          { path: 'hotels/:page', name: 'db-hotels', component: () => import('@/views/otlc/reservations/hotels/hotelsView.vue')},
          { path: 'categories/:page', name: 'db-category', component: () => import('@/views/otlc/reservations/categories/categoryView.vue')},
          { path: 'roomType', name: 'db-room-type', component: () => import('@/views/otlc/reservations/roomType/roomTypeView.vue')},
          { path: 'information/:page', name: 'information-voucher', component: () => import('@/views/otlc/reservations/voucher/informationView.vue')},
          { path: 'agencies', name: 'db-agencies', component: () => import('@/views/otlc/reservations/agencies/agenciesView.vue')},
          { path: 'restriction', name: 'contract-room', component: () => import('@/views/otlc/reservations/contract_restriction/contractRestrictionView.vue')},
          { path: 'dates', name: 'canceled-date', component: () => import('@/views/otlc/reservations/dates/dateView.vue')},
          { path: 'arrivals', name: 'arrivals', component: () => import('@/views/otlc/reservations/Arrivals/ArrivalView.vue')},
          { path: 'emails', name: 'emails', component: () => import('@/views/otlc/reservations/email/emailView.vue')},
          { path: 'reservations-consult', name: 'reservations-consult', component: () => import('@/views/otlc/reservations/consult/consulReservation.vue')},
          { path: 'promo', name: 'promo', component: () => import('@/views/otlc/reservations/promocion/promoView.vue')},
        ]
      },
      { path : 'master', 
      children: [
        { path: 'payment-methods/:page', name:'payment-methods', component: () => import('@/views/otlc/payment_methods/PaymentMethods.vue')},
        { path: 'movement-types/:page', name:'movement-types', component: () => import('@/views/otlc/movement_types/MovementTypes.vue')},
        { path: 'collection-schedule/:page', name:'collection-schedule', component: () => import('@/views/otlc/collection_schedule/CollectionSchedule.vue') }
        ],
      },
      { path : 'membership', 
        children: [
          { path: 'info/membership', name: 'consult-membership', component: () => import('@/views/otlc/membership/MembershipView.vue') },
          ],
      }
    ]
  },
  {
    path: '/help-desk',
    component: HomeView,
    children: [
      { path: 'incidents',
        children: [
          { path: 'register/:page', name: 'hd-create', component: () => import('@/views/helpdesk/incidents/RegisterView.vue') },
          { path: 'config', name: 'hd-config', component: () => import('@/views/helpdesk/incidents/ConfigView.vue') },
          { path: 'summary', name: 'q-summary', component: () => import('@/views/helpdesk/incidents/summary/SummaryView.vue') },
        ]
      }
    ]
  },
  {
    path: '/other-clubs',
    component: HomeView,
    children: [
      { path: 'membership',
        children: [
          { path: 'zafiro', name: 'membership-zaf', component: () => import('@/views/other-clubs/zafiroView.vue') },
          { path: 'list-zaf', name: 'list-osg', component: () => import('@/views/other-clubs/ListOsg.vue') },
        ]
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=>{
  if ( to.name !== 'login' && !store.getters.isAuthenticated ){
    next('/login')
  }else if (to.name === 'admin'){
    if (store.getters.isAuthenticated)
      if (store.getters.isAdmin) next(); else next('/index');
    else next('/login')
  } else if(to.name === 'index'){
    store.commit('setTitle','INICIO')
    next()
  }else next();
})

export default router
