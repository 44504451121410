export default{
    namespaced: true,
    state: {
        list_detail: []
    },
    getters: {
    },
    mutations: {
        updateDetail(state, newVal) {
            state.list_detail = newVal
        }
    },
    actions: {
    },
    modules: {
    }
}