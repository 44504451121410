import { Buffer } from 'buffer'

export function isValidJwt (jwt) {
    if (!jwt) return false
    const tokenParts = jwt.token.split('.')
    if (tokenParts.lenght < 3) {
        return false
    }
    const data = JSON.parse(Buffer.from(tokenParts[1], 'base64').toString('binary'))
    const exp = new Date(data.exp * 1000)
    const now = new Date()
    return now < exp
}

export function isAdmin(jwt) {
    if (!jwt) return false
    const tokenParts = jwt.token.split('.')
    if (tokenParts.lenght < 3) {
        return false
    }
    const data = JSON.parse(Buffer.from(tokenParts[1], 'base64').toString('binary'))
    const rol = data.sub.split('-')[1].split('.')
    return ['ADMIN', 'DEVELOPER'].includes(rol[1])
}