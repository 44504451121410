<template>
	<div id="principal" class="container-fluid">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
}
</script>

<style>
	@import '@/assets/fontawesome/css/all.min.css';

	div.input-group>button {
		z-index: 0 !important;
	}

	.table-responsive{
		max-height: 50em !important;
	}

	.modal-xl { 
		max-width: 80% !important; 
	}
</style>
