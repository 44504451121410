<template>
	<div class="sidebar">
        <div class="logo-details">
			<router-link @click="previousGo('INICIO')" to="/index">
            	<img src="@/assets/img/oasis_ico.png" alt="logo">
			</router-link>
            <span class="logo_name">Oasis The Leisure Club</span>
        </div>
        <ul v-if="data.data" class="nav-links">
            <li v-for="menu in data.data" :key="menu.ID" class="menu">
                <div class="icon-link">
                    <a href="#" @click.prevent="submenu">
                        <i :class="['bx', menu.ICON]"></i>
                        <span class="link_name">{{menu.NAME}}</span>
                    </a>
                    <i class='bx bxs-chevron-down arrow' @click="submenu"></i>
                </div>
                <ul class="sub-menu">
                    <li><a class="link_name" href="#">{{menu.NAME}}</a></li>
                    <li v-for="submenu in menu.SUBMENU" :key="submenu.ID">
						<div class="icon-link" @click="showfunc">
							<a href="#" >{{submenu.NAME}}</a>
							<i class='bx bxs-chevron-down sub-arrow' ></i>
						</div>
						<ul class="sub-menus">
                    		<li v-for="f in submenu.FUNCTION" :key="f.ID">
								<router-link v-if="validRoute(f)" @click="previousGo(f.TITLE)" :to="{name:f.FRONT_ROUTE, params:{ table: f.TABLENAME, page: 1}}" class="link_names">
									<span class="text-wrap">{{ f.DESCRIPTION }}</span>
								</router-link>
							</li>
						</ul>
					</li>
                </ul>
            </li>
        </ul>
    </div>
    <section class="home-section">
        <div class="home-content">
            <i class='bx bx-menu' @click="openMenu"></i>
            <span class="text">{{title}}</span>
			<div class="container-fluid d-flex justify-content-end">
				<div class="d-flex flex-row-reverse align-items-center">
					<div class="dropdown">
						<a href="" class="d-flex align-items-center text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  id="udropdown">
							<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4JjG0S1e_YbfVaI2MMI6b6dZkJqhHfFOuiA&usqp=CAU" alt="hugenerd" width="40" height="40" class="rounded-circle">
							<span class="d-none d-sm-inline mx-1">{{employee.username}}</span>
						</a>
						<ul class="dropdown-menu text-small shadow">
							<li><a class="dropdown-item" href="#">Perfil</a></li>
                            <li v-if="this.isAdmin">
                                <router-link @click="previousGo('PANEL DE CONTROL')" to="/admin" class="dropdown-item">Panel de control</router-link>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li><h6 class="dropdown-header">HOTEL</h6></li>
                            <template v-for="item in data_hotel" :key="item">
                            <li v-if="item.HO_HOTEL_DEF"><a @click="select_hotel" class="dropdown-item" href="#"><input type="hidden" name="Hotel"  ref="the_hotel" :value="item.HO_ID_HOTEL">{{item.HO_ID_HOTEL}}</a></li>
                            </template>
							<li><hr class="dropdown-divider"></li>
							<li>
                                <router-link to='/login' custom v-slot="{href, navigate}" >
                                    <a class="dropdown-item" :href="href" @click="wrapNav(navigate, $event)">Cerrar sesión</a>
                                </router-link>
							</li>
						</ul>
					</div>
					<li class="mode">
						<div class="moon-sun">
							<i class="bx bx-sun icon sun"></i>
						</div>
						<span id="theme-mode" class="mode-text text"></span>
						<div class="toggle-switch" @click="darkmode()">
							<span class="switch"></span>
						</div>
						<div class="moon-sun">
							<i class="bx bx-moon icon moon"></i>
						</div>
					</li>
				</div>
			</div>
        </div>
        <div class="content" style="z-index: 0 !important;">
            <!-- <cuper></cuper> -->
            <router-view></router-view>
        </div>
    </section>
</template>

<style>
@import 'https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css';

.current::after{
    content: '✔';
    position: absolute;
    left: 90%;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    background-color: #2E2E38; /* var(--bs-primary);*/
    transition: all 0.5s ease;
    z-index: 100;
}

.sidebar.close{
    width: 78px;
}

.sidebar .logo-details{
    background: #2E75B6;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar .logo-details i{ 
    font-size: 30px;
    color: #fff;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
}

.sidebar .logo-details .logo_name{ 
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    padding-left: 10px;
    /* transition: all 0s ease;
    transition-delay: 5s; */
}

.sidebar.close .logo-details .logo_name{
    /* transition-delay: 0s; */
    opacity: 0;
    display: none;
    pointer-events: none;
}

.sidebar .nav-links{
    height: 100%;
    padding-top: 30px 0 150px 0;
    overflow-y: auto !important;
    padding-left: 0;
}

.sidebar.close .nav-links{
    overflow: visible !important;
}

.sidebar .nav-links{
    overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar{
    display: none;
}

.sidebar .nav-links li{
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}

.sidebar .nav-links li.menu{
    border-bottom: solid 1px #fff;
}

.sidebar .nav-links li.menu:hover{
    background: var(--bs-primary);
}

.sidebar .nav-links li .icon-link{
	display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.sidebar.close .nav-links li .icon-link{
    display: block;
    pointer-events: none;
}

.sidebar.close .nav-links li:hover .icon-link{
    pointer-events: auto;
}

.sidebar .nav-links li i{
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.sidebar .nav-links li.showMenu i.arrow{
    transform: rotate(-180deg);
}

.sidebar .nav-links li li.showMenu i.sub-arrow{
    transform: rotate(-180deg) !important;
}

.sidebar.close .nav-links i.arrow{
    display: none;
}

.sidebar .nav-links li a{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sidebar .nav-links li a .link_name{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin: 13px 0;
}

.sidebar.close .nav-links li a .link_names,
.sidebar.close .nav-links li a .link_name{
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li .sub-menu{
    padding: 6px 6px 14px 8px;
    margin-top: 0;
    background-color: var(--bs-primary);
    display: none;
}

.sidebar .nav-links li:hover .sub-menu  li .submenus,
.sidebar .nav-links li:hover .sub-menu{
    background: var(--bs-primary) !important;
	opacity: 1;
}

.sidebar .nav-links li .sub-menu .link_names,
.sidebar .nav-links li .sub-menu .link_name{
    display: none;
}

.sidebar .nav-links li.showMenu .sub-menu{
    display: block;
}

.sidebar .nav-links li li.showMenu .sub-menus .link_names{
    display: block;
}

.sidebar .nav-links li .sub-menu li a,
.sidebar .nav-links li .sub-menu a{
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6 ;
    transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover{
    opacity: 1;
}

.sidebar.close .nav-links li .sub-menu{
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    transition: 0s;
    opacity: 0;
    display: block;
    pointer-events: none;
}

.sidebar.close .nav-links li li:hover .sub-menus,
.sidebar.close .nav-links li:hover .sub-menu{
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.sidebar.close .nav-links li .sub-menu .link_name{
    display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name{
    font-size: 18px;
    opacity: 1;
    display: block;
}

.sidebar.close .nav-links li .sub-menu.blank,
.sidebar .nav-links li .sub-menu.blank{
    opacity: 1;
    /* pointer-events: auto; */
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank{
    top: 50%;
    transform: translateY(-50%);
}

.home-section{
    position: relative;
    /* background: var(--bs-primary); */
    height: 100vh;
    left: 300px;
    width: calc(100% - 300px + ((var(--bs-gutter-x) * 0.5)*2));
    margin-left: calc(var(--bs-gutter-x) * 0.5 * -1);
    margin-right: calc(var(--bs-gutter-x) * 0.5 * -1);
    transition: all 0.5s ease;
}

.sidebar.close ~ .home-section{
    left: 78px;
    width: calc(100% - 78px + ((var(--bs-gutter-x) * 0.5)*2));
}

.home-section .home-content{
    height: 60px;
    background: var(--bs-primary) !important;
    display: flex;
    align-items: center;
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 0;
    z-index: 1;
}

/* .dropdown-menu{
    position: relative;
    z-index: 0 !important;
} */

.home-section .home-content span,
.home-section .home-content a.dropdown-toggle{
	color: #BDD7EE;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text
{
	color: #BDD7EE;
    font-size: 35px;
	white-space: pre;
}

.home-section .home-content .text{
    font-size: 26px;
    /* font-weight: 600; */
}

li.mode{
    height: 50px;
    margin-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;
}
li.mode .icon, 
li.mode .text{
    color: #BDD7EE;
    transition: var(--tran-02);
}
 .mode .moon-sun{
  /* height: 50px; */
  width: 20px;
  display: flex;
  align-items: center;
 }
.mode i{
  position: absolute;
}

.mode i.moon{
  opacity: 0;
}

.mode .toggle-switch{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 60px;
  cursor: pointer;
}

.toggle-switch .switch{
  position: relative;
  height: 20px;
  width: 40px;
  border-radius: 25px;
  background: white;
}

.switch::before{
  content: '';
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  background: var(--bs-secondary);
  transition: all 0.3s ease;
}

body.dark .switch::before{
  left: 23px;
}
body.dark .mode i.sun{
  opacity: 0;
}
body.dark .mode i.moon{
  opacity: 1;
}
</style>

<script>
// import Cuper from '@/components/cuper/CuperComponent.vue';
import { request } from '@/helpers/avalon/requests'
import { mapState, mapMutations, mapGetters} from 'vuex'
export default {
    // components: {Cuper},
	data() {
		return {
			data : {},
      		employee: {},
            data_hotel: null,
            hotel:null,
		}
	},
    methods: {
        ...mapMutations(['setTitle', 'setJwtToken']),
        async previousGo(title){
            await this.setTitle(title)
        },
        select_hotel: function(e){
            var hotel_selected = e.target.children.Hotel.value
            var hotel_data = this.data_hotel.find(item => item.HO_ID_HOTEL === hotel_selected)
            document.querySelectorAll('.current').forEach(function(x) {
                x.classList.remove('current');
            });
            e.target.classList.add('current')
            localStorage.setItem('hotel', JSON.stringify(hotel_data));
        },
        submenu: function(e) {
            if (e.target.tagName === 'I') {
                e.target.parentElement.parentElement.classList.toggle('showMenu')
            } else e.target.parentElement.parentElement.parentElement.classList.toggle('showMenu')
        },
        showfunc: function(e) {
            if (e.target.tagName === 'I' || e.target.tagName === 'A') {
                e.target.parentElement.parentElement.classList.toggle('showMenu')
            } else e.target.parentElement.classList.toggle('showMenu')
        },
        openMenu: function(e) {
            document.querySelector('body').querySelector('.sidebar').classList.toggle('close')
        },
        darkmode(){
        let body = document.querySelector('body')
            body.classList.toggle('dark')
            const darkMode = document.querySelector('body').classList.value.includes('dark')      
            localStorage.setItem('dark-mode', darkMode);
        },
		wrapNav(navigate, event) {
			this.setJwtToken({jwt: null})
            localStorage.setItem('hotel', '');
			navigate(event)
		},
        // previousGo(title){
        //     this.setTitle(title)
        // },
        validRoute(f){
            try {
                this.$router.resolve({name:f.FRONT_ROUTE, params:{ table: f.TABLENAME, page: 1}}) 
                return true
            } catch {
                return false
            }
        }
    },
	computed: {
        ...mapGetters(['isAdmin']),
    	...mapState(['jwt', 'title', 'user'])
	},
    async created() {
    	this.employee = JSON.parse(this.user)
		this.data = await request('get', 'system', '/menu/get', null, JSON.parse(localStorage.getItem('jwt')))
        let data_hotel = await request('get', 'system', '/hotel/get', null, JSON.parse(localStorage.getItem('jwt')))
        if (data_hotel){
            this.data_hotel = data_hotel.data
            localStorage.setItem('hoteles', JSON.stringify(this.data_hotel));
        }
    },
	async updated() {
        this.hotel = localStorage.getItem('hotel')
        if (this.hotel != null && this.hotel != '') {
            var refs = await this.$refs
            var hotel_selected = JSON.parse(this.hotel)
            if ('the_hotel' in refs) {
                refs['the_hotel'].forEach(function(x) {
                    if (x.value == hotel_selected.HO_ID_HOTEL) {
                        x.parentElement.classList.add('current')
                    }
                });
            }
        }
	}
}
</script>