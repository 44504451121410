var axios = require('axios')
const urls = {
        'api':       'api/avalon',
        'interface': 'avalon/interface',
        'auth':      'auth',
        'system':    'system',
        'otlc':      'otlc',
        'otlcrva':   'otlcrva',
        'hoteles':   'hoteles/interface',
        'helpdesk':  'helpdesk',
    }

export async function request(method, base, url, form, jwt, filename=null) {
    let destiny = `${urls[base]}${url}`
    let heads = {'Authorization': `Bearer ${jwt.token}`}
    switch(method) {
        case 'get':
            return await axios.get(destiny, {params: form, headers: heads}).then((rsp) => rsp.data).catch((rsp)=>rsp.response.data)
        case 'post':
            return await axios.post(destiny, form, {headers: heads}).then((rsp) => rsp.data)
        case 'postResp':
            return await axios.post(destiny, form, {headers: heads}).then((rsp) => rsp.data).catch((rsp)=>rsp.response.data)
        case 'download':
            return await axios.post(destiny, form, {headers: heads, responseType: 'blob'}).then((rsp) => {
                
                var fileURL = window.URL.createObjectURL(new Blob([rsp.data]));
                var fURL = document.createElement('a');

                fURL.href = fileURL;
                fURL.setAttribute('download', filename);
                document.body.appendChild(fURL);

                fURL.click();
            })
        default:
            return null
    }
}

export async function hardwareRequest(url) {
    var hardware = axios.create({baseURL:'http://localhost:5001/'})
    return await hardware.get(url, {onUploadProgress: progressEvent => console.log(progressEvent.loaded)})
}

export function authRequest(url, form, jwt=null) {
    let heads = null
    if (jwt) {
        heads = {'Authorization': `Bearer ${jwt.token}`}
        console.log(heads)
    }
    let destiny = `${urls['auth']}${url}`
    return axios.post(destiny, form, {headers: heads})
}

export async function avalonRequest(url, form) {
    var guest = axios.create({baseURL: 'http://avalon.oasishoteles.ohr:81/AWWebAPI/api/aw/'})
    return await guest.post(url, form)
}