<template>
  <div class="flex-nowrap">
    <nav-component/>
  </div>
</template>


<style scoped>
 @import 'https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css';

 li{
    height: 50px;
    margin-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;
}
li .icon, 
li .text{
    color: white;
    transition: var(--tran-02);
}
 .mode .moon-sun{
  width: 20px;
  display: flex;
  align-items: center;
 }
.mode i{
  position: absolute;
}

.mode i.moon{
  opacity: 0;
}

.mode .toggle-switch{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 60px;
  cursor: pointer;
}

.toggle-switch .switch{
  position: relative;
  height: 20px;
  width: 40px;
  border-radius: 25px;
  background: white;
}

.switch::before{
  content: '';
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  background: var(--bs-secondary);
  transition: all 0.3s ease;
}

body.dark .switch::before{
  left: 23px;
}
body.dark .mode i.sun{
  opacity: 0;
}
body.dark .mode i.moon{
  opacity: 1;
}
 </style>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import NavComponent from '@/components/system/NavComponent.vue';
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'HomeView',
  data() {
    return {
      employee: {},
      darkSwitch: null
    }
  },
  components: {
    HelloWorld,
    NavComponent
  },
  methods: {
    ...mapMutations(['setJwtToken']),
    wrapNav(navigate, event) {
      this.setJwtToken({jwt: null})
      navigate(event)
    },
    darkmode(){
      
      let body = document.querySelector('body')
        body.classList.toggle('dark')
        const darkMode = document.querySelector('body').classList.value.includes('dark')   
        localStorage.setItem('dark-mode', darkMode);
    }
  },
  computed: {
    ...mapState(['user', 'jwt', 'title'])
  },
  mounted() {
    this.employee = JSON.parse(this.user)
  }
}
</script>
